import React,{useState} from 'react';
import './ContactForm.css'
import Privacy from './Privacy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from "react-router-dom";


import { faX, faSpinner  } from '@fortawesome/free-solid-svg-icons'

const ContactForm = (props) => {
    const navigate = useNavigate();

    const [name,setName]=useState("");
    const [num,setNum]=useState("");
    const [email,setEmail]=useState("");
    const [city,setCity]=useState("");
    const [rules,setRules]=useState(false);

    const [error,setError]=useState(null);
    const [loading,setLoading]=useState(false);
    const [result,setResult]=useState(null);
    const [sent,setSent]=useState(false);

    const [showPriv, setShowPriv]=useState(false);



    const url='https://pv.comeko.pl/comekoLanding/sendmail.php';
const sendForm=async(url,data)=>{

    const resp=await fetch(url,{
    method:'POST',
    body:JSON.stringify(data),
    headers:{
      'Content-Type': 'application/json'
    }     });
      const json = await resp.json();
        return json;
    }



const handleSubmit=()=>{
    setError(null);
if(name.length<3)
{
setError("Wpisz poprawne imię")
}
else if(num.length<9)
{
    setError("Wpisz poprawny numer telefonu")
}
else if (city.length<3)
{
    setError("Wpisz miasto")
}
else if(rules===false)
{
    setError("Zaakceptuj zgodę na przetwarzanie danych.")
}
else 
{
setLoading(true);
const data={
name,
num,
email,
city

}

const sform= sendForm(url,data)
if(sform)
{
    sform.then((result)=>{
if(result.rezultat===1)
{
setLoading(false);
setSent(true)

setResult("Dane wysłane. Skontaktujemy się najszybciej jak to będzie mozliwe. ")
navigate("/podziekowanie");


}
else {
    setLoading(false);
    setResult("Nie udało się wysłać wiadomości. Prosimy spróbować jeszcze raz lub skontaktować się z nami pod adresem email: comeko@comeko.pl lub telefonicznie: 577 909 330 ")



}

    })
}



}

}

    return ( <div className="contactForm">

<div className="modal">
    <div className="bar"><FontAwesomeIcon onClick={()=>props.close(0)}icon={faX} size="2x" /></div>

<div className="form">

<h2> Zamów kontakt</h2>
    <p> Zostaw nam swoje dane a bezpłatnie odpowiemy na wszystkie pytania, przyjedziemy, wykonamy audyt, projekt oraz wycenimy dla Ciebie instalację. Ostateczna decyzja zależeć będzie od Ciebie!  </p>
   {sent===false&& <input type="text" value={name} onChange={e=>setName(e.target.value)} placeholder='Imię *'/>}<br/>
      {sent===false&&  <input type="text" value={num} onChange={e=>setNum(e.target.value)} placeholder='Nr. telefonu *'/>}<br/>
      {sent===false&&  <input type="text"  value={email} onChange={e=>setEmail(e.target.value)} placeholder='E-mail'/>}<br/>
      {sent===false&&  <input type="text" value={city} onChange={e=>setCity(e.target.value)} placeholder='Miejscowość *'/>}<br/>

   {sent===false&&<><input type="checkbox" value={rules} onChange={(e)=>{setRules(e.target.value)}} id="rules1"/> <label for="rules1">Wyrażam zgodę na kontakt Comeko Sp. z o.o. celem przedstawienia oferty. Akceptuję przy tym politykę prywatności oraz RODO.  </label><br/></> }
   {sent===false&&<p onClick={()=>setShowPriv(true)}> Polityka prywatności oraz rodo dostępne TUTAJ</p>}
    {error&&<div className='error'> {error}</div>}
    {result&&<div className="sucess"><b>{result}</b></div>}
<br/>  {sent===false&&<>{loading===true?<button  onClick={handleSubmit} className="spinnerLoading"> <FontAwesomeIcon  icon={faSpinner} size="1x"/></button>:<button id="clickSubmit" onClick={handleSubmit}> Proszę o kontakt</button>}</>}

</div>
</div>
{showPriv===true&&<Privacy close={setShowPriv}/>}
    </div> );
}
 
export default ContactForm

