import React,{useEffect} from 'react';
import './Wwh.css'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Wwh = (props) => {
    useEffect(()=>{
        AOS.init();
    
    
    },[])
    return (  
        <div className="wwh">
<h2 data-aos="fade-up"> Zainteresowany? Chcesz poznać szczegóły naszej oferty?</h2>
<p data-aos="fade-up">  W Comeko oprócz instalacji fotowoltaicznej znajdziesz także:</p>
<ul>
    <li data-aos="fade-up">Magazyny energii</li>
    <li data-aos="fade-up">Pompy ciepła</li>
    <li data-aos="fade-up">Ładowarki samochodowe</li>

</ul>
<p data-aos="fade-up"> Wypełnij formularz klikając przycisk ponizej i poznaj naszą ofertę szytą na miarę Twoich potrzeb!</p>
            
            <div className="skontaktuj" onClick={()=>props.open(1)}>Skontaktuj się z nami! - kliknij <b>TUTAJ</b> i wypełnij formularz!</div>
        </div>
    );
}
 
export default Wwh;