import './App.css';
import FirstPv from './components/FirstPv';
import {Route, Routes,BrowserRouter} from 'react-router-dom';
import Podziekowanie from './components/Podziekowanie';

function App() {
  return (
    <div className="App">
   
   <BrowserRouter>
      <Routes>
<Route path='/' element={<FirstPv/>} />
<Route path='/podziekowanie' element={<FirstPv/>} />

</Routes>
</BrowserRouter>


    </div>
  );
}

export default App;
