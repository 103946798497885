import React,{useEffect} from 'react';
import './WhyWe.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
const WhyWe = (props) => {
    useEffect(()=>{
        AOS.init();
    
    
    },[])
    return ( <div id="wiecej" className="whyWe">
<div className="first">
<h1 data-aos="fade-up" > Fotowoltaika</h1>
<h2 data-aos="fade-up"> Dlaczego teraz? </h2>
<p data-aos="fade-up"> Fotowoltaika stała się w ostatnich latach jednym z najbardziej pożądanych rozwiązań energetycznych, zarówno wśród prywatnych inwestorów, jak i firm. Dlaczego? Po pierwsze, panele fotowoltaiczne pozwalają na oszczędność na rachunkach za energię, dzięki czemu inwestycja w nie zwraca się już po kilku latach użytkowania. Po drugie, fotowoltaika jest jednym z najbardziej ekologicznych sposobów pozyskiwania energii, co wpisuje się w dzisiejsze trendy proekologiczne. Po trzecie, rozwój technologii zwiększył efektywność paneli fotowoltaicznych, co oznacza większą wydajność przy mniejszej ilości paneli. Po czwarte, wiele państw oferuje dotacje i ulgi podatkowe dla osób inwestujących w fotowoltaikę, co znacznie obniża koszty inwestycji. Zatem, jeśli chcesz oszczędzać na rachunkach za energię, dbać o środowisko i skorzystać z korzyści finansowych, fotowoltaika jest idealnym rozwiązaniem dla Ciebie. </p>
</div>

<div className="second" data-aos="fade-up">
<h2 data-aos="fade-up"> Nie kupuj "w ciemno"! </h2> 
<p data-aos="fade-up"> Zachęcamy do wypełnienia <b>formularza kontaktowego</b>. Po wypełnieniu w ciagu <b>24 </b> godzin zadzwoni do Ciebie nasz specjalista. Od niego uzyskasz odpowiedzni na wszystkie nurtujące Cię pytania, <b>profesjonalny projekt</b> ukazujący średni roczny uzysk planowanej instalacji uwzględniający wszelkie zacienienia a takze przygotujemy dla Ciebie <b>wycenę instalacji</b>. Ostateczna decyzja co do wyboru wykonawcy i podzespołów będzie nalezeć do Ciebie. Spotkanie ze specjalistą, projekty i wyceny są całkowicie <b> darmowe.</b></p>
<button data-aos="fade-up" className='buttonContact' onClick={()=>props.open(1)}>Chcę poznać ofertę</button>

</div>
<div className="third" data-aos="fade-up">
<h2 data-aos="fade-up" className='thirdh2'>  Fotowoltaika z Comeko </h2>
<p data-aos="fade-up" className="thirdgeneral"> Jeśli tu jesteś to prawdopodobnie od jakiegoś czasu zastanawiasz się nad <b>instalacją fotowoltaiczną</b>. Jest to świetna inwestycja w przyszłość. Jeśli szukasz <b>doświadczonego</b> oraz <b>rzetelnego</b> wykonawcy sprawdź co chcemy Ci zaoferować ponizej lub zamów kontakt aby poznać nas osobiście. </p>
<button data-aos="fade-up" className='buttonContact' onClick={()=>props.open(1)}>Chcę poznać ofertę</button>

<div className="element">
<div className="photo" data-aos="fade-right" id="photolokalna"></div>
<div className="text" data-aos="fade-left">
<h2 style={{color:"#91c84b"}}>Lokalna firma</h2>
<p> Firma Comeko to lokalna firma fotowoltaiczna z siedzibą w <b>Szczecinie</b>, świadcząca usługi na terenie województw <b>zachodniopomorskiego</b> oraz <b>lubuskiego</b>. Jesteśmy dumni z tego, że możemy zapewnić naszym klientom kompleksową obsługę, w tym montaż oraz serwis paneli fotowoltaicznych <b>na miejscu</b>. Dzięki temu, że <b>jesteśmy blisko</b>, możemy <b>szybko</b> i <b>sprawnie</b> reagować na wszelkie potrzeby naszych klientów, a także zapewnić <b>profesjonalną</b> i <b>rzetelną</b> obsługę.</p>

</div>
</div>

<div className="element sectext">
<div className="text " data-aos="fade-right">

<h2 style={{color:"#00b0f0"}}> Własne ekipy montażowe</h2>
<p> Dzięki temu, że posiadamy własne ekipy montażowe mamy pewność <b>jakości</b> naszych instalacji. Nasi pracownicy posiadają wszelkie uprawnienia, doświadczenie oraz wiedzę aby zamontowana instalacja była w pełni bezpieczna i pracowała przez <b>długie lata</b>. Obecnie wiele firm opiera swoje montaże o ekipy podwykonawcze zatem to czy klient trafi na rzetelnego montera zależy od losu. </p>
</div>
<div className="photo" data-aos="fade-left" id="photoekipy"></div>

</div>
<div className="element">
<div className="photo"id="photoplgw" data-aos="fade-right" ></div>
<div className="text" data-aos="fade-left">
<h2 style={{color:"#91c84b"}}> Polskie gwarancje</h2>
<p> W Comeko zawsze <b>stawiamy na najwyższą jakość</b> oferowanych produktów. Dlatego wszystkie moduły, falowniki i inne podzespoły, pochodzą od jednego z naszych dwóch partnerów: Selfa oraz Corab, którzy zapewniają w pełni <b>polską gwarancję</b> na wszystkie swoje produkty. Wybierając produkty od <b>polskich producentów</b>, naszym klientom gwarantujemy bezpieczeństwo i pewność, że w razie awarii, naprawa i wymiana sprzętu odbywać się będą w kraju, bez konieczności odsyłania produktów na odległe kontynenty. Oferujemy <b>tylko sprawdzone i bezpieczne rozwiązania</b>, które cieszą się uznaniem na rynku.</p>
</div>
</div>

<div className="element sectext">
    <div className="text " data-aos="fade-right">
        <h2 style={{color:"#00b0f0"}}> Doświadczenie od 2017 roku</h2>
        <p>Od 2017 roku, czyli już od kilku lat, specjalizujemy się w instalacjach paneli fotowoltaicznych na terenie województw zachodniopomorskiego i lubuskiego. Nasza firma Comeko ma na swoim koncie wiele zrealizowanych projektów, które cieszą się uznaniem naszych klientów. Posiadamy w tym zakresie wiele doświadczenia w branży fotowoltaicznej, dzięki czemu jesteśmy w stanie zaoferować naszym klientom <b>fachowe doradztwo</b> i <b>kompleksową obsługę</b> na każdym etapie realizacji projektu. Z naszą firmą masz pewność, że Twoja inwestycja w panele fotowoltaiczne będzie zrealizowana z <b>najwyższą jakością</b> i <b>zgodnie z najnowszymi standardami</b>.</p>
    </div>
    <div className="photo" data-aos="fade-left" id="photostart"></div>
</div>
<div className="element">
    <div className="photo" id="photocorab" data-aos="fade-right"></div>
<div className="text" data-aos="fade-left">
    <h2 style={{color:"#91c84b"}}> Partner Corab</h2>
    <p> Corab jest <b>największym dystrybutorem części do instalacji fotowoltaicznych</b> w Polsce. Jest także <b>producentem systemów montażowych</b> o tej samej nazwie oraz modułów i falowników fotowoltaicznych marki własnej - <b>Encore</b>. Comeko będąc w programie partnerskim <b>Corab Partner</b> należy do jednej z nielicznych firm będących w gronie <b>najlepszych firm fotowoltaicznych w Polsce</b>. Podzespoły dystrybucji CORAB posiadają wieloletnią polską gwarancję. Corab posiada także własny dział serwisowy. </p>
</div>

</div>
<div className="element sectext" style={{minHeight:"350px"}}>
<div className="text " data-aos="fade-right">
    <h2 style={{color:"#00b0f0"}}> Partner Selfa</h2>
    <p> Selfa to firma będąca na rynku od niemal jednego stulecia <b>związana ze Szczecinem</b> od samego początku. Jako <b>producent modułów fotowoltaicznych</b> oraz falowników poza <b>najwyższą jakością</b> zapewnia także <b>Polską gwarancję</b> na swoje produkty nawet <b>do 30 lat</b>. Moduły Selfy osiągają o wiele wyższe uzyski niż przewidują to projekty. <b>Comeko</b> jako srebrny partner Selfy montuje produkty tego producenta od samego początku istnienia firmy posiadając <b>100% zadowolenie klientów</b>. Jeśli chcesz poznać więcej szczegółów, <b>skontaktuj się z nami!</b>  </p>
</div>
<div className="photo"  data-aos="fade-left" id="photoselfa"></div>


</div>
<button className='buttonContact'data-aos="fade-up" onClick={()=>props.open(1)}>Chcę poznać ofertę</button>

</div>


<div className="kontakt" onClick={()=>props.open(1)}>Bezpłatny audyt, projekt i wycena?   Kliknij!</div>

    </div>
    );
}
 
export default WhyWe;