import React, {useEffect} from 'react';
import './Colab.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import calbud from "../photos/calbud.png"
import drobimex from "../photos/drobimex.png"
import multi from "../photos/multi.png"
import printgroup from "../photos/printgroup.png"
import grzs from "../photos/gszs.png"


const Colab = () => {
    useEffect(()=>{
        AOS.init();
    
    
    },[])
    return (<div className="colab" data-aos="fade-up">

<h2>  Zaufali nam: </h2>
<div className="colablogos">
<div className="colablogo">
            <img src={calbud} alt="calbud"/>
        </div>

        <div className="colablogo">
            <img src={drobimex} alt="drobimex"/>
        </div>

        <div className="colablogo">
            <img src={multi} alt="multi"/>
        </div>

        <div className="colablogo">
            <img src={printgroup} alt="printgroup"/>
        </div>
        <div className="colablogo">
            <img src={grzs} alt="Gospodarstwo Rolne Zbigniew Szychulski"/>
        </div>



</div>
        
    </div>  );
}
 
export default Colab;