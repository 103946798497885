import React from 'react';
import './Header.css'
import logo from '../photos/logo.png'
const Header = (props) => {
    return ( <div className="Header">
        <div className="miniNav"><img src={logo} alt="logo comeko"/></div>
<div className="modal">
<div className="left"><h1> Fotowoltaika w Zachodniopomorskim</h1>
<h2>Obniż z nami swoje rachunki za prąd! </h2>
<h3>Możesz płacić nawet 90% mniejsze rachunki za prąd! </h3>
<a className="button" href="#wiecej">Więcej informacji</a>
</div>
<div className="right">
<div className="formheader" onClick={()=>props.open(1)}>
    <h2> Zamów kontakt</h2>
    <p> Zostaw nam swoje dane a bezpłatnie odpowiemy na wszystkie pytania, przyjedziemy, wykonamy audyt, projekt oraz wycenimy dla Ciebie instalację. Ostateczna decyzja zależeć będzie od Ciebie!  </p>
    <input type="text" placeholder='Imię'/><br/>
    <input type="text" placeholder='nr. telefonu'/><br/>
    <input type="text" placeholder='adres E-mail'/><br/>
    <input type="text" placeholder='Miejscowość'/><br/>

    <input type="checkbox" id="rules"/> <label for="rules">Wyrażam zgodę na kontakt Comeko Sp. z o.o. celem przedstawienia oferty. </label>
<br/><button> Chcę wycenę</button>
</div>
</div>
</div>


    </div> );
}
 
export default Header;