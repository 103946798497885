import React, {useState} from 'react';

import Header from './Header';
import Colab from './Colab';
import WhyWe from './WhyWe';
import Reviews from './Reviews';
import Wwh from './Wwh';
import ContactForm from './ContactForm';
const FirstPv = () => {


    const [dispContact,setDispContact]=useState(false);

    return ( 

    <>
    <Header open={setDispContact}/>
   <WhyWe open={setDispContact}/>
   <Reviews open={setDispContact}/>
   <Colab open={setDispContact}/>
   <Wwh open={setDispContact} />
    {dispContact&&<ContactForm close={setDispContact}/>}
    </>
     );
}
 
export default FirstPv;